// Classes
import TemplateInductees from './templates/TemplateInductees';

// Legacy Components
import addMember from './components/add_member';
import donateButton from './components/donate-button';
import donorSlider from './components/donor-slider';
import eventsSlider from './components/events-slider';
import exhibitListingSlider from './components/exhibit-listing-slider';
import flexible5050ContentSlide from './components/flexible-5050-content-slide';
import flexibleAccordion from './components/flexible-accordion';
import flexibleHomepageHero from './components/flexible-homepage-hero';
import flexibleMediaGallery from './components/flexible-media-gallery';
import flexiblePartnerSlider from './components/flexible-partner-slider';
import flexibleTabsCards from './components/flexible-tabs_cards';
import flexibleTabs from './components/flexible-tabs';
import general from './components/general';
import hmSlider from './components/hm-slider';
import honouredMembers from './components/honoured-members';
import lightboxInit from './components/lightbox';
import mobileNav from './components/mobile-nav';
import additionalDetailsTab from './components/product-additional-details-tab';
import shop from './components/shop';
import slickSliderGallery from './components/slick-slider-gallery';
import video from './components/video';
import smoothScroll from './components/smooth-scroll';
import popup from './components/popup';

// Libraries
import $ from 'jquery';

class App {
	constructor(page, initialized) {
		this.page = page;
		this.initialized = initialized;

		if (this.initialized == false) {
			if (document.readyState === 'loading') {
				document.addEventListener('DOMContentLoaded', this.init());
			} else {
				this.init();
			}
		}
	}

	init() {
		this.initialized = true;

		addMember();
		donateButton();
		donorSlider();
		eventsSlider();
		exhibitListingSlider();
		flexible5050ContentSlide();
		flexibleAccordion();
		flexibleHomepageHero();
		flexibleMediaGallery();
		flexiblePartnerSlider();
		flexibleTabsCards();
		flexibleTabs();
		general($);
		hmSlider();
		honouredMembers();
		lightboxInit();
		mobileNav();
		additionalDetailsTab();
		shop();
		slickSliderGallery();
		video();
		smoothScroll($);
		popup();

		switch (true) {
			case this.page.classList.contains('page-template-template-inductees'):
				new TemplateInductees();
				break;
		}
	}
}

new App(document.querySelector('body'), false);
