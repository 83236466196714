import $ from 'jquery';

function honouredMembers() {
	window.Site = window.Site || {};

	Site.Members = $.extend(
		{
			intval: {},
			pages: [],
			params: {
				search: null,
				induction: null,
				sport: null,
				category: null,
				paged: Site.paged,
			},

			// Determine page URL
			url: (() => {
				const canonicalLink = $('link[rel="canonical"]').attr('href');
				return canonicalLink ? canonicalLink.replace(/\/+$/, '') : '';
			})(),

			// Get URL parameters
			getParam(key, string) {
				return (string.match(new RegExp(`${key}=(.*?)(&|$)`)) || ['', ''])[1];
			},

			// Preloader animations
			preloader: {
				start(callback) {
					$('[data-member-list-container],[data-member-pagination-container]')
						.stop(true, true)
						.fadeTo(500, 0);
					$('#preloader').stop(true, true).fadeIn(500, callback);
				},
				stop(callback) {
					$('[data-member-list-container],[data-member-pagination-container]')
						.stop(true, true)
						.fadeTo(500, 1);
					$('#preloader').stop(true, true).fadeOut(500, callback);
				},
			},

			// Timeout with namespace
			timeout(ns, callback, time) {
				this.intval[ns] && clearTimeout(this.intval[ns]);
				this.intval[ns] = setTimeout(() => {
					callback();
					clearTimeout(this.intval[ns]);
				}, time);
			},

			// Load updated values from WP
			load() {
				const obj = Site.Members;
				const data = {
					search: $('#search').val(),
					induction: $('#induction').val(),
					sport: $('#sport').val(),
					category: $('#category').val(),
					paged: Site.paged,
				};

				obj.preloader.start(() => {
					$.get(
						Site.ajax_url,
						{ ...obj.params, ...data, action: 'honored_members' },
						(response) => {
							const result = $.extend(
								{
									members: '',
									pagination: '',
								},
								response
							);

							$('[data-member-list-container]').html(
								result.members || $('#no-entries').html()
							);
							$('[data-member-pagination-container]').html(result.pagination);

							$('[data-member-pagination-container] a[href]').each(function () {
								const pageNum = $(this)
									.attr('href')
									.match(/paged=([0-9]+)/)[1];
								$(this).attr('href', `${Site.Members.url}/page/${pageNum}`);
							});

							if (JSON.stringify(data) !== JSON.stringify(obj.params)) {
								const pageUrl = `${Site.Members.url}/page/${data.paged}/?search=${data.search}&induction=${data.induction}&sport=${data.sport}&category=${data.category}`;
								window.history.pushState(null, null, pageUrl);
								obj.pages.push(pageUrl);
							}

							obj.preloader.stop();
						},
						'json'
					);
				});
			},

			// Initialize parameters
			init() {
				const url = window.location.href;
				this.params = {
					search: this.getParam('search', url),
					induction: this.getParam('induction', url),
					sport: this.getParam('sport', url),
					category: this.getParam('category', url),
				};
				return this;
			},
		},
		{}
	).init();

	// Event Listeners
	$('#search').keyup(() => {
		Site.Members.timeout(
			'filter',
			() => {
				Site.paged = 1;
				Site.Members.load();
			},
			500
		);
	});

	$('#induction, #sport, #category').change(() => {
		Site.paged = 1;
		Site.Members.timeout('filter', Site.Members.load, 500);
	});

	$('#member-pagination').on('click', '.page-numbers', function (e) {
		e.preventDefault();
		Site.paged = $(this)
			.attr('href')
			.match(/paged?(=|\/)([0-9]+)/)[2];
		Site.Members.load();
	});

	$(window).on('popstate', (event) => {
		const location = event.target.location;
		if (
			location.pathname.includes('honoured-members') &&
			Site.Members.pages.length > 0
		) {
			Site.Members.pages.pop();
			Site.Members.params = {
				search: Site.Members.getParam('search', location.href),
				induction: Site.Members.getParam('induction', location.href),
				sport: Site.Members.getParam('sport', location.href),
				category: Site.Members.getParam('category', location.href),
			};
			Site.paged = (location.href.match(/paged?(=|\/)([0-9]+)/) || [
				'',
				'',
				1,
			])[2];
			Site.Members.load();
		}
	});
}

export default honouredMembers;
