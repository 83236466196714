import $ from 'jquery';

function flexibleTabs() {
	function defaultDonations(i) {
		if (i == 0) {
			$('input:radio[name="wc_label_price"]').filter('[value=25]').click();
			$('input:radio[name="wc_label_price"]')
				.filter('[value=25]')
				.parent()
				.addClass('wc-active');
		} else {
			$('input:radio[name="wc_label_price"]').filter('[value=100]').click();
			$('input:radio[name="wc_label_price"]')
				.filter('[value=100]')
				.parent()
				.addClass('wc-active');
		}
	}

	$('.tab-container').each(function (i, tab) {
		if (
			$('body').hasClass('page-template-template-donations') ||
			$('body').hasClass('page-template-template-membership')
		) {
			if ($(tab).hasClass('right-open')) {
				var button = $(tab).find('[data-tab="1"]');
				$(button).addClass('open');
				$(button).next('.tab-content').fadeIn();
				if ($(tab).hasClass('donations-tab')) {
					$('[data-donation="1"]').addClass('open');
				}
			} else {
				var button = $(tab).find('[data-tab="0"]');
				$(button).addClass('open');
				$(button).next('.tab-content').fadeIn();
				if ($(tab).hasClass('donations-tab')) {
					$('[data-donation="0"]').addClass('open');
				}
				if ($(tab).hasClass('membership-tab')) {
					$('[data-membership="0"]').addClass('open');
				}
			}
		} else if (i === 0) {
			$(this).addClass('open');
			$(this).next('.tab-content').fadeIn();
		}
	});

	$('.tab-container').on('click', '.tab-header', function () {
		var tabContainer = $(this).closest('.tab-container');
		var tabHeader = $(tabContainer).find('.tab-header');
		var tabContent = $(tabContainer).find('.tab-content');

		if (
			$('body').hasClass('page-template-template-donations') &&
			tabContainer.hasClass('tab-1')
		) {
			var tabInstance = $(this).data('tab');
			var tabClose = tabInstance == 0 ? 1 : 0;

			$('[data-donation="' + tabInstance + '"]').toggleClass('open');
			$('[data-donation="' + tabClose + '"]').removeClass('open');

			$('input:radio[name="wc_label_price"]').each(function () {
				$(this).parent().removeClass('wc-active');
			});
			defaultDonations(tabInstance);
		}

		if ($('body').hasClass('page-template-template-membership')) {
			var tabInstance = $(this).data('tab');
			var tabClose = tabInstance == 0 ? 1 : 0;

			$('[data-membership="' + tabInstance + '"]').toggleClass('open');
			$('[data-membership="' + tabClose + '"]').removeClass('open');
		}

		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
			$(this).next('.tab-content').hide();
		} else {
			$(tabHeader).removeClass('open');
			$(tabContent).hide();

			var $tab = $(this);
			var $content = $tab.next('.tab-content');

			$tab.toggleClass('open');
			$content.fadeToggle();
		}
	});

	$(document).ready(function () {
		defaultDonations(0);
	});
}

export default flexibleTabs;
